<template>
  <div>
    <PageHeader />
    <b-row>
      <b-col>
        <b-button-group
          v-if="filteredMenu.length > 0"
          class="mb-2"
        >
          <b-button
            v-for="menu in filteredMenu"
            :key="menu"
            :variant="menu === currentFilter ? `secondary` : `light`"
            @click="currentFilter = menu"
          >
            {{ menu === 'auto'? $t('credits.transaction.auto'):
              $te(`credits.transaction.${mode}_${menu}`)?$t(`credits.transaction.${mode}_${menu}`): menu
            }}
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <component
      :is="selectedComponent"
      @fetch="fetchData"
    />
  </div>
</template>

<script>
export default {
  page: {
    title: 'รายงานเครดิตผู้เล่น',
  },
  components: {
    RequestTransactions: () =>
      import('../../../components/transactions/requests'),
    AutoTransactions: () => import('../../../components/transactions/auto'),
  },
  data() {
    return {
      currentFilter: '',
    }
  },
  computed: {
    mode() {
      return this.$route.path.split('/')[this.$route.path.split('/').length - 1]
    },
    filteredMenu() {
      switch (this.mode) {
        case 'all':
        case 'deposit':
          return ['request', 'history', 'auto']
        case 'withdraw':
          return ['request', 'history']
        default:
          return []
      }
    },
    selectedComponent() {
      switch (this.currentFilter) {
        case 'request':
        case 'history':
          return 'RequestTransactions'
        case 'auto':
          return 'AutoTransactions'
        default:
          return ''
      }
    },
  },
  watch: {
    currentFilter(val) {
      if (val) {
        this.fetchData()
      }
    },
  },
  created() {
    this.currentFilter = this.filteredMenu[0]
    this.fetchData()
  },
  methods: {
     fetchData() {
    },
  },
}
</script>
